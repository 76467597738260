<template>
	<div
		tabindex="0"
		id="camera-capture"
	></div>
</template>

<script>
export default {
	name: 'm-capture',

	data() {
		return {
			eid: null,
		};
	},

	props: {
		idType: {
			type: Number,
			default: 0,
		},
		authorization: {
			type: String,
		},
	},

	watch: {
		idType(value) {
			if (value > 0) {
				this.startCapture();
			}
		},
	},

	mounted() {
		const eid = window.EID.videoId('#camera-capture', { lang: 'es' });

		this.eid = eid;

		eid.on('completed', ({ id }) => {
			eid.turnOff();
			this.$emit('completed', id);
		});

		eid.on('failed', ({ notification: { name } }) => {
			this.$emit('failed', name);
		});

		eid.on('phaseStarting', (phase) => {
			this.$emit('phase', phase.name);
			phase.continue();
		});
	},

	methods: {
		startCapture() {
			const { eid, idType, authorization } = this;

			eid.turnOn();
			eid.start({
				authorization,
				idType,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
#camera-capture {
	width: calc(100vw - 2rem);
	margin: 0 auto;
	height: calc(100vh - 6rem);
}
</style>

<style lang="scss">
#videoid-container #video-loading p,
div.videoid-notification.notification-image h1 {
	color: $color-primary !important;
}

.videoid-notification.notification-text,
div.videoid-notification.notification-image .bar {
	background-color: $color-primary !important;
}

#videoid-overlay .spinner span div {
	border-top-color: $color-primary !important;
}

#videoid-mark-roi {
	border-color: $color-primary !important;
}

#videoid-container #video-loading #videoid-progress-bar #bar {
	background: linear-gradient(to right, transparent 0%, $color-primary 100%) !important;
}
</style>
