<template>
	<div :id="dynamicId">
		<call-help></call-help>
		<app-stepper :step-number="6"></app-stepper>
		<div class="camera-container">
			<m-capture
				v-if="!errorCode"
				ref="eid"
				class="camera-fixed"
				:id-type="$store.state.onboarding.documentType"
				:authorization="$store.state.eid.token"
				@completed="onCompleted"
				@failed="onFailed"
				@phase="onPhase"
			></m-capture>

			<div
				v-if="errorCode === 'Document.NotAllowed'"
				class="errorContainer"
			>
				<h2 class="text-center text-format-1">Algo no ha ido bien</h2>
				<p class="block m-2 tf text-center">
					Asegúrese de estar mostrando un DNI español e inténtelo de nuevo.
				</p>
				<button
					class="button-confirm my-5 marginB mb-sm-1"
					data-testid="retry-button"
					@click="start"
				>
					Reintentar
				</button>
			</div>
		</div>
		<app-mobile-call></app-mobile-call>
	</div>
</template>

<script>
import CallHelp from '@/components/call-help';
import AppMobileCall from '@/components/app-mobile-call';
import AppStepper from '@/components/app-stepper';
import MCapture from '@/modals/m-capture';
import MCamPermissions from '@/modals/m-cam-permissions';

export default {
	name: 'app-camera-capture',

	components: {
		CallHelp,
		AppStepper,
		AppMobileCall,
		MCapture,
	},

	data() {
		return {
			errorCode: '',
			dynamicId: 'app-camera-capture',
		};
	},

	methods: {
		start() {
			this.errorCode = '';
			this.$nextTick(() => {
				setTimeout(() => {
					this.$refs.eid.startCapture();
				}, 100);
			});
		},

		async onCompleted() {
			try {
				await this.$store.dispatch('eid/getData');
			} catch {}

			this.$store.dispatch('onboarding/saveProcess', {}).then(async () => {
				const { data: offices } = await this.getOfficesData();

				if (offices.length === 1) {
					this.$store
						.dispatch('onboarding/saveProcess', { officeId: offices[0].id })
						.then(() => this.$router.push('app-confirmation'));
				} else {
					this.$router.push({
						name: 'app-election-office',
						params: { offices },
					});
				}
			});
		},

		async getOfficesData() {
			const res = await this.$store.dispatch(
				'onboarding/getOffices',
				this.$store.state.onboarding.id
			);

			return res.data;
		},

		onFailed(name) {
			if (name === 'CameraPermission.Denied') {
				return this.$store.dispatch('modal/open', MCamPermissions);
			}

			if (name === 'Document.NotAllowed') {
				this.errorCode = name;
				return;
			}
		},

		onPhase(phase) {
			this.dynamicId = phase === 'FACE' ? 'app-identity-identification' : 'app-camera-capture';
		},
	},

	created() {
		this.start();
	},
};
</script>

<style lang="scss" scoped>
.camera-fixed {
	width: 100% !important;
	max-width: 700px !important;
	margin: 0 auto !important;
	height: 400px !important;
	margin-top: 32px !important;
}

.buttongroup {
	display: flex;
	margin: 80px auto;
	justify-content: space-evenly;
}

.button {
	width: 150px;
	height: 150px;
	background: #eee;
	border-radius: 4px;
	padding: 32px;
	cursor: pointer;
}

.button img {
	width: 100%;
	height: 100%;
}

.errorContainer {
	padding: 20px;
	margin-top: 40px;
	text-align: center;
}
</style>
